@use 'styles/includes' as *;

.Header {
    $root: &;

    width: 100%;
    position: fixed;
    z-index: 9;
    background-color: transparent;
    transform: translateY(0);
    transition:
        transform $transition-linear,
        opacity $transition-linear,
        background-color $transition-linear,
        color $transition-linear;

    &--HideHeader {
        transform: translateY(-100%);
    }

    &--IsScrolling {
        background-color: $white;
    }

    &--HomePage {
        @include media-max(xxl) {
            color: $white;

            &#{$root}--IsScrolling {
                background-color: $black;
            }
        }
    }

    &--White {
        color: $white;

        &#{$root}--IsScrolling {
            background-color: $black;
        }
    }

    &--IsExpanded {
        @include media-max(xl) {
            color: $black;
        }
    }

    &--IsOpen {
        @include media(xl) {
            background-color: $white;
            color: $black;
        }
    }

    &__Container {
        @extend %container;

        display: flex;
        align-items: center;
        justify-content: space-between;

        @include media-max(xl) {
            padding-top: 1.6rem;
            padding-bottom: 1.6rem;
        }

        @include media(xl) {
            width: 100%;
            gap: 4rem;
        }
    }

    &__Logo {
        padding: 0.23rem 0;
        padding: 0 0 0.4rem;
        flex-shrink: 0;
        display: inline-flex;
    }

    &__Navigation {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;

        @include media(xl) {
            display: none;
        }
    }

    &__Button {
        padding: 6px 14px;
        font-family: $font-family-bold;
        font-size: 1.2rem;
        font-weight: 600;
        line-height: normal;
        color: inherit;
        border-radius: 4px;
        border: 1px solid #000;

        #{$root}--IsExpanded & {
            display: none;
        }

        #{$root}--White &,
        #{$root}--HomePage & {
            border-color: $white;
        }
    }

    &__Toggle {
        padding: 0;
        height: 2rem;
        border: 0;
        background-color: transparent;
        position: relative;

        &::before {
            content: '';
            padding: 1.6rem;
            position: absolute;
            inset: 0;
            top: 0;
            transform: translate(-0.4rem, -0.6rem);
        }

        @include media(xl) {
            display: none;
        }
    }

    &__Line {
        width: 2.4rem;
        height: 0.2rem;
        background-color: $black;
        display: block;

        #{$root}--IsExpanded & {
            visibility: hidden;
        }

        #{$root}--White &,
        #{$root}--HomePage & {
            background-color: $white;
        }

        &::before,
        &::after {
            content: '';
            width: 2.4rem;
            height: 0.2rem;
            background-color: $black;
            display: block;
            transition: transform $transition-ease-out-expo;

            #{$root}--White &,
            #{$root}--HomePage & {
                background-color: $white;
            }

            #{$root}--IsExpanded &,
            #{$root}--IsExpanded:hover & {
                visibility: visible;
                background-color: $black;
            }
        }

        &::before {
            transform: translateY(-6px);

            #{$root}__Toggle:hover & {
                transform: translateY(-8px);
            }

            #{$root}--IsExpanded &,
            #{$root}--IsExpanded:hover & {
                transform: translateY(0px) rotate(45deg);
            }
        }

        &::after {
            transform: translateY(4px);

            #{$root}__Toggle:hover & {
                transform: translateY(6px);
            }

            #{$root}--IsExpanded &,
            #{$root}--IsExpanded:hover & {
                transform: translateY(-2px) rotate(-45deg);
            }
        }
    }

    &__Menu {
        padding: 66px 20px 175px 20px;
        width: 100%;
        height: 100dvh;
        font-family: $font-family-regular;
        font-size: 20px;
        font-weight: 500;
        line-height: 110%;
        background-color: $white;
        position: fixed;
        visibility: hidden;
        inset: 0;
        z-index: -1;
        transform: translateY(-100vh);
        transition: transform $transition-ease-out-expo;

        #{$root}--IsExpanded & {
            transform: translateY(0);
            visibility: visible;
        }
    }

    &__Inner {
        overflow: scroll;
        height: 100%;
    }

    &__LinkList {
        margin: 40px 0 0;
        display: grid;
        gap: 18px;
    }

    &__Bottom {
        padding: 16px 20px;
        width: 100%;
        background-color: $white;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
    }

    &__Login,
    &__Customer {
        padding: 12px 20px;
        text-align: center;
        border: 1px solid $black;
        border-radius: 4px;
        display: block;
        color: $black;
    }

    &__Login {
        margin: 10px 0 0;
        background-color: $black;
        color: $white;
    }

    &__SocialLinks {
        margin: 20px 0 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__SocialLink {
        padding: 2px 5px;
        font-family: $font-family-regular;
        font-size: 1rem;
        line-height: 110%;
        text-transform: uppercase;
        text-decoration: underline;
        text-underline-offset: 2px;
    }
}

.Accordion {
    $root: &;
    position: relative;

    &__Button {
        padding: 8px 0;
        width: 100%;
        font-family: $font-family-regular;
        font-size: 20px;
        font-weight: 500;
        line-height: 110%;
        text-align: left;
        color: inherit;
        border: 0;
        border-bottom: 1px solid $grey-40;
        background-color: transparent;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 2rem;
    }

    &__Icon {
        display: inline-block;
        color: #808080;
    }

    &__Grid {
        margin: 20px 0 20px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
    }

    &__Link {
        grid-column: 2;

        &--IsActive {
            text-decoration: underline;
            text-underline-offset: 2px;
        }
    }

    &__Title {
        margin: 0 0 20px;
        padding: 8px 0;
        width: 100%;
        font-family: $font-family-regular;
        font-size: 20px;
        font-weight: 500;
        line-height: 110%;
        color: inherit;
        border: 0;
        border-bottom: 1px solid $black;
        background-color: transparent;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 2rem;

        span {
            transform: rotate(90deg);
        }
    }

    &__Category {
        color: #808080;
    }

    &__Menu {
        padding: 66px 20px 0 20px;
        position: fixed;
        background-color: $white;
        visibility: hidden;
        inset: 0;
        z-index: 2;
        overflow: scroll;
        transform: translateX(100vw);
        transition: transform $transition-ease-out-expo;

        #{$root}--IsOpen & {
            transform: translate(0);
            visibility: visible;
        }
    }

    &__List {
        display: grid;
        gap: 16px;
    }
}

.Navigation {
    $root: &;
    display: none;

    @include media(xl) {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 4rem;
    }

    &__Left {
        display: flex;
    }

    &__Right {
        margin-left: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
    }

    &__Button {
        padding: 6px 20px;
        background-color: transparent;
        border-radius: 4px;
        border: 1px solid $black;
        transition:
            background-color $transition-ease-out-expo,
            color $transition-ease-out-expo;

        @media (hover: hover) {
            &:hover {
                background-color: $black;
                color: $white;
            }
        }

        #{$root}--White & {
            border-color: $white;

            @media (hover: hover) {
                &:hover {
                    background-color: $white;
                    color: $black;
                }
            }
        }

        #{$root}--HomePage & {
            @include media-max(xxl) {
                border-color: $white;
                @media (hover: hover) {
                    &:hover {
                        background-color: $white;
                        color: $black;
                    }
                }
            }
        }

        #{$root}--IsOpen & {
            border-color: $black;

            @media (hover: hover) {
                &:hover {
                    background-color: $black;
                    color: $white;
                }
            }
        }
    }

    &__List {
        font-size: 1.6rem;
        line-height: 100%;
        display: flex;
    }

    &__Item {
        padding: 13px 10px;
        position: relative;
    }

    &__FocusButton {
        border: 0;
        background-color: transparent;
        position: absolute;
        bottom: 7px;
        left: 50%;
        transform: translateX(-50%);
        opacity: 0;
        color: $black;
        pointer-events: none;

        &:focus-visible {
            opacity: 1;
        }

        &--IsOpen {
            transform: translateX(-50%) rotate(180deg);
        }

        #{$root}--White & {
            color: $white;

            &--IsOpen {
                color: $black;
            }
        }

        #{$root}--IsOpen & {
            color: $black;
        }
    }

    &__Link {
        padding: 13px 0;
        font-family: $font-family-regular;
        font-size: 16px;
        font-weight: 500;
        line-height: 110%;
        color: inherit;
        background-color: transparent;
        border: 0;
        display: inline-block;

        @media (hover: hover) {
            &:hover {
                text-underline-offset: 2px;
                text-decoration: underline;

                cursor: pointer;
            }
        }

        &--IsActive {
            text-underline-offset: 2px;
            text-decoration: underline;
            text-decoration-color: $black;

            &:hover {
                text-decoration-color: $grey-40;
            }
        }

        #{$root}--White & {
            &--IsActive {
                text-decoration-color: $white;

                &:hover {
                    text-decoration-color: $grey-40;
                }
            }
        }

        #{$root}--IsOpen & {
            &--IsActive {
                text-decoration-color: $black;
            }
        }
    }

    &__Menu {
        padding: 2rem 0;
        height: 450px;
        background-color: $white;
        color: $black;
        border-bottom: 1px solid $black;
        transform: translateY(-100%);
        transition:
            transform $transition-linear,
            opacity $transition-linear;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        z-index: -1;
        pointer-events: none;
        visibility: hidden;

        #{$root}--IsOpen & {
            pointer-events: auto;
            transform: translateY(0);
            visibility: visible;
        }

        &--InActive {
            z-index: -2;
        }
    }

    &__Grid {
        @include media(xl) {
            width: 100%;
            max-width: 129rem;
            margin-left: auto;
            margin-right: auto;
            padding: 8rem 3rem 5rem 3rem;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 2rem;
        }
    }

    &__Card {
        grid-column: 4;
    }
}

.Column {
    $root: &;

    font-family: $font-family-regular;
    font-size: 1.6rem;
    font-weight: 450;
    line-height: 110%;

    @include media-max(xl) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
    }

    &__Title {
        margin: 0 0 1rem;
        color: $grey-60;
    }

    &__List {
        @include media(xl) {
            height: 272px;
            overflow-y: auto;
            position: relative;
        }

        &::-webkit-scrollbar {
            width: 1px;
        }

        &::-webkit-scrollbar-track {
            background: $grey-40;
        }

        &::-webkit-scrollbar-thumb,
        &::-webkit-scrollbar-thumb:hover {
            background: $black;
        }

        &::after {
            @include media(xl) {
                content: '';
                width: 100%;
                height: 80px;
                display: block;
                position: sticky;
                bottom: 0;
                background: linear-gradient(
                    180deg,
                    rgba(255, 255, 255, 0) 0%,
                    $white 100%
                );
                background-position: bottom;
                pointer-events: none;
            }
        }
    }

    &__Item {
        &:not(:last-child) {
            margin-bottom: 1rem;
        }
    }

    &__Link {
        color: $black;

        &:focus-visible {
            outline-offset: 0px;
        }

        @media (hover: hover) {
            &:hover {
                text-decoration: underline;
                text-underline-offset: 0.4rem;
                text-decoration-color: $grey-40;
            }
        }

        &--IsActive {
            text-underline-offset: 2px;
            text-decoration: underline;
            text-decoration-color: $black;

            &:hover {
                text-decoration-color: $grey-40;
            }
        }
    }

    &__Icon {
        margin: 0 0 0 8px;
        position: relative;
        width: 11px;
        height: 11px;
        display: inline-block;
        overflow: hidden;

        span {
            position: absolute;
            visibility: hidden;
            opacity: 0;
            inset: 0;
            left: -11px;
            transition:
                left $transition-linear,
                opacity $transition-linear,
                visibility $transition-linear;

            #{$root}__Link:hover & {
                visibility: visible;
                opacity: 1;
                left: 0;
            }
        }
    }
}
